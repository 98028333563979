
// react 
import React, { Component } from 'react';

import {   DateInput,   TimeInput,   DateTimeInput,   DatesRangeInput } from 'semantic-ui-calendar-react';

import { Button, Icon }  from 'semantic-ui-react';

var Moment = require('moment');
Moment.locale('pl');

//var Moment = moment();

//import SemanticDatepicker from 'react-semantic-ui-datepickers';
//import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
//import { DateTimeInput, DateInput, TimeInput } from 'semantic-ui-react-datetimeinput';


// here we have some test components 

const SwitchModeButton = ({mode, handler}) => {
    let message = mode == "history" ? "Go Live!" : "Go History!";

    return (
        <Button color = { mode == "default" ? 'violet' : 'green'} icon style={{width: 150}} onClick={handler}>
           <Icon name={mode == "default" ? 'pause' : 'play'}/> 
           <span style={{marginLeft: 10}}>{message}</span>
        </Button>
    )
}

const TopBar = ({switchModeHandler, selectedButton, buttonsHandler, changeDate, timestamp, mode}) => {
    const buttons = { labels: ["12h", "3d", "7d"] };


    return (
        <div style={{ top: 0, width: '100%', height: 40, background: '#00000080', borderBottom: '1px solid #999', display: 'flex' }} key="topbar">

            <SwitchModeButton key={"switch-button"} handler={switchModeHandler} mode={mode}  />
            {/* <SemanticDatepicker value={new Date(timestamp * 1000)} key={"global-datepicker"} onChange={(event, data) => console.log('datepicker date: ', data.value)} />
            <TimeInput dateValue={new Date(timestamp * 1000)} /> */}
            <DateTimeInput disabled = {mode == "default"} closable = {true} disableMinute = {true} localization = 'pl' dateTimeFormat = "YYYY-MM-DD HH:mm" value={Moment(new Date(timestamp * 1000)).format('YYYY-MM-DD HH:mm')} onChange={changeDate} />
            <div key={"buttons"} style={{ height: '100%', marginLeft: 5 }}>
                {buttons.labels.map((txt) => {
                    return <Button key={txt} style={{ height: '100%' }} primary={txt == selectedButton} onClick={(ev) => buttonsHandler(txt)}>{txt}</Button>
                })}
            </div>
            {/* <div key="mzo-text" style={{float: 'right', marginLeft: 'auto', fontWeight: 'bold', fontSize: '16px', color: '#05746D', lineHeight: '40px'}}>Miejski Zakład Oczyszczania w Pruszkowie sp. z o.o.</div> */}
            <div key="jsw-logo" style={{float: 'right', marginLeft: 'auto', fontWeight: 'bold', fontSize: '16px', color: '#05746D', lineHeight: '40px'}}>
                <img key="jsw-logo-image" src={require('app/images/jswkoks_logo_white.svg').default} style={{ height: "40px", marginLeft: 10, marginRight: 10, padding: 2 }} />
            </div>
            {/* <img key="mzo-image" src={require('app/images/mzo-logo-only-transparent.png').default} style={{marginLeft: 10, marginRight: 10}}/> */}
            {/* <img key="jsw-group-image" src={require('app/images/jsw_grupa_white.svg').default} style={{marginLeft: 10, marginRight: 10, padding: 2}}/>
            <div key="jsw-group-text"style={{marginLeft: 0, marginRight: 10, padding: 2, color: 'white', fontFamily: "'Source Sans Pro', sans-serif", fontSize: '1.6rem', fontWeight: 400, verticalAlign: 'middle', lineHeight:"40px" }}>Grupa JSW</div> */}
        </div>
    )
}

export default TopBar;