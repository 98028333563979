import React, {Component} from 'react';
import './App.css';
import MeteoPanel from './graphql/MeteoPanel';

import 'semantic-ui-css/semantic.min.css'

function App() {
  //const url = "https://alerty.sensorbox.eu/cas_alerty/webapp";
  //const url = "http://mzo-pruszkow.panel.test.sensorbox.pl/webapp"
  //const url = "https://amp-dg.panel.sensorbox.pl/webapp"
  //const url = "http://ipis.cas.sensorbox.pl/webapp"
  //const url = "https://jsw-jadwiga.panel.sensorbox.pl/webapp"
  // const url = "https://jsw-radlin.panel.sensorbox.pl/webapp"
  const url = "webapp"
  return (
    // <MeteoPanel configUrl="https://pmpro.dacsystem.pl/webapp" dataUrl="https://hesgdynia.dustbox.pl/cas_envag/webapp"/>
    <MeteoPanel configUrl={url} dataUrl={url} />
  );
}

export default App;
